#heroImageContainer {
    width: 20rem;
    height: 28rem;
    position: relative;
    top: -70px;
}

#heroFrame {
    height: 0;
    width: 0;
}

#heroImage {
    height: 28rem;
    clip-path: url(#heroClipPath);
}

#heroImageShadow {
    filter: drop-shadow(3px 3px 5px var(--primary-color));
}

.heroLeft {
    max-width: 768px;
    position: relative;
}