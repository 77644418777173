.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --background-color: rgb(248 250 252);
  --blue-color: #D0E2FF;
  --primary-color: rgb(75 85 99);
  --secondary-color: #65a30d;
}


#mainApp {
  background-image: url('data:image/svg+xml,%3Csvg%20viewBox%3D%2275%2070%2075%2075%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill%3D%22%23D0E2FF%22%20d%3D%22M22%2C-32.5C30.9%2C-33%2C42%2C-31.9%2C43.8%2C-26.2C45.6%2C-20.5%2C38.1%2C-10.2%2C37.7%2C-0.2C37.3%2C9.8%2C44%2C19.5%2C44.7%2C29.6C45.5%2C39.8%2C40.3%2C50.3%2C31.8%2C46.1C23.4%2C41.9%2C11.7%2C23.1%2C2.4%2C18.9C-6.9%2C14.7%2C-13.8%2C25.2%2C-17.8%2C26.9C-21.9%2C28.6%2C-23.2%2C21.4%2C-21.4%2C15.4C-19.5%2C9.4%2C-14.5%2C4.7%2C-20.8%2C-3.6C-27.1%2C-12%2C-44.7%2C-24%2C-44%2C-25.5C-43.3%2C-27%2C-24.2%2C-17.9%2C-13.8%2C-16.5C-3.4%2C-15%2C-1.7%2C-21.1%2C2.4%2C-25.4C6.6%2C-29.6%2C13.2%2C-31.9%2C22%2C-32.5Z%22%20transform%3D%22translate(100%20100)%22%20/%3E%3C/svg%3E');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: scale(var(--zoom-level, 1)); /* Use CSS variable for scaling */
  transform-origin: center; /* Zoom from the center */
  transition: transform 0.2s ease-out; /* Smooth transition */
}